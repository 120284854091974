import { ChannelProgram, Episode } from '@package/sdk/src/api';
import type { Genre } from '@package/sdk/src/api/content/types/genre';
import type { MainPageBlock } from '@package/sdk/src/api/content/types/main-page';
import type { Media } from '@package/sdk/src/api/content/types/media';
import type { Serial } from '@package/sdk/src/api/content/types/serial';
import { ENDPOINTS } from '@package/sdk/src/api/endpoints';
import { MainPageMapper } from '@package/sdk/src/api/main-page/main-page';
import { ApiMainPage } from '@package/sdk/src/api/main-page/main-page-types';

import { formatDuration, pluralize } from '../../utils/format';
import { translate } from '../localization/translate';
import type { RequestService } from '../request-service';
import { HTTPRequestMethod } from '../request-service';

export class PlaylistService {
  constructor(private readonly $http: RequestService) {}

  public abort(message = 'Cancelled by user'): void {
    this.$http.abort(message);
  }

  public getLimit(value = 0): string {
    const limit = parseInt(value?.toString() || '');

    if (isNaN(limit)) {
      return '';
    }

    return !isNaN(limit) ? limit + '+' : '';
  }

  public getSubtitle(
    genres: Genre[],
    item: Media | ChannelProgram,
    type: 'media' | 'channel-program' = 'media',
  ): string {
    const media = item as Media;
    const channelProgram = item as ChannelProgram;

    const countries = media.countries;
    const rating = media.imdbRating;
    const duration = media.duration;
    const year = media.year;

    const fragments: string[] = [];

    if (type === 'media') {
      const isSerial = media.contentType === 'serial';
      const isEpisode = media.contentType === 'episode';

      const episode = media as Episode;
      const serial = media as Serial;

      if (isEpisode && episode.seasonNumber && episode.number) {
        fragments.push(
          `${episode.seasonNumber} ${pluralize(episode.seasonNumber, translate('pluralizedUnit.season'))}`,
        );

        fragments.push(`${episode.number} ${pluralize(episode.number, translate('pluralizedUnit.seriesLowercase'))}`);
      } else if (isSerial) {
        const seasonNumber = serial.seasonsCount;

        if (seasonNumber) {
          fragments.push(`${seasonNumber} ${pluralize(seasonNumber, translate('pluralizedUnit.season'))}`);
        }
      }
    }

    if (type === 'channel-program') {
      if (channelProgram.seasonNumber && channelProgram.episodeNumber) {
        fragments.push(
          `${channelProgram.seasonNumber} ${pluralize(channelProgram.seasonNumber, translate('pluralizedUnit.season'))}`,
        );
        fragments.push(
          `${channelProgram.episodeNumber} ${pluralize(channelProgram.episodeNumber, translate('pluralizedUnit.seriesLowercase'))}`,
        );
      }
    }

    if (rating) {
      fragments.push(`${rating.toFixed(2)}  IMDB`);
    }

    if (duration >= 60) {
      fragments.push(formatDuration(duration, 'hhmm'));
    }

    if (year) {
      fragments.push(String(year));
    }

    if (type === 'media') {
      if (media.genres.length > 0) {
        const genreId = media.genres[0].id;
        const genre = genres.find((v) => v.id === genreId) as Genre;

        fragments.push(genre.title);
      }

      if (countries.length > 0) {
        const country = countries[0].title;

        fragments.push(country);
      }
    }

    if (type === 'channel-program') {
      const channelProgram = item as ChannelProgram;

      if (channelProgram.genre?.length > 0) {
        fragments.push(channelProgram.genre[0]);
      }
    }

    return fragments.join(', ');
  }

  public getContentSubtitle(media: Media): string {
    // @ts-expect-error
    const seasonNumber = (media as Serial)?.seasons?.length ?? media?.seasonNumber ?? media?.seasonCount ?? 1;
    const duration = parseInt(media?.duration?.toString() ?? '');
    const year = parseInt(media?.year?.toString() ?? '');
    const countries = media?.countries;
    const isSerial = media?.contentType === 'serial';
    const fragments: Array<string | number | null> = [];

    if (!isNaN(year)) {
      fragments.push(year);
    }

    if (Array.isArray(countries) && countries.length > 0) {
      const country = countries[0]?.title;
      fragments.push(country || null);
    }

    if (!isNaN(duration) && duration >= 60 && !isSerial) {
      fragments.push(formatDuration(duration, 'hhmm'));
    }

    if (isSerial) {
      fragments.push(`${seasonNumber} ${pluralize(seasonNumber, 'pluralizedUnit.season').toLowerCase()}`);
    }

    return fragments.filter(Boolean).join(' · ');
  }

  public async fetchBlocks(options: { page: number }): Promise<MainPageBlock[]> {
    const { data } = await this.$http.request<ApiMainPage>(
      {
        method: HTTPRequestMethod.Get,
        url: ENDPOINTS.PAGES_MAIN,
        query: {
          page: options.page,
          per_page: 5,
          includes: 'primary_content',
        },
      },
      { withToken: true, skipTokenValidation: true, canAbort: false },
    );

    return MainPageMapper.map(data);
  }
}
